<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h1 class="text-2xl font-bold">General Terminal Requests</h1>
      <!-- <div class="bg-blue-100 p-5">
        <p>
          Please note that all downloads are in .txt formats. You will need to
          change it to .csv format after downloading it. Merci.
        </p>
      </div> -->
      <div class="flex justify-end items-end my-5">
        <button
          @click="downloadCSV"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-600 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Download CSV
        </button>
      </div>

      <div class="flex items-center my-5">
        <input
          type="text"
          class="inline-block px-3 ring-2 outline-none ring-blue-500 rounded-sm w-80 text-sm py-2"
          name=""
          placeholder="Search by Business Name, Email and Phone number"
          id=""
          v-model="search"
        />
        <button
          @click="handleSearch"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Search
        </button>
        <button
          @click="clearFilter"
          class="block uppercase shadow ml-2 text-sm bg-red-900 text-white hover:bg-red-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Reset
        </button>
      </div>
      <div class="mb-5 flex mt-20">
        <button
          @click="filterToggle = !filterToggle"
          :class="
            filterToggle ? 'bg-blue-900 text-white' : 'bg-blue-50 text-blue-800'
          "
          class="block uppercase shadow ml-2 text-sm font-bold text-white hover:bg-blue-100 hover:text-blue-800 focus:shadow-outline focus:outline-none py-3 px-3 rounded float"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-filter"
            viewBox="0 0 16 16"
            style="display: inline; font-weight: 900"
          >
            <path
              d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
            />
          </svg>
          Filter by
        </button>
      </div>
      <div class="mt-4 flex bg-gray-50 p-5" v-if="filterToggle">
        <div class="block">
          <select
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2 mt-4"
            v-model="filterByParams"
          >
            <option value="">Set Filter Params</option>
            <option value="status">Terminal Deployment Status</option>
            <option value="terminaltype">Terminal Type</option>
            <option value="state">State</option>
            <option value="agentName">Requesting ISA</option>
          </select>
        </div>
        <div class="block" v-if="filterByParams == 'status'">
          <select
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2 mt-4"
            v-model="filterByStatus"
          >
            <option value="">Filter by Status</option>
            <option :value="type.status" v-for="(type, i) in types" :key="i">
              {{ type.status }}
            </option>
          </select>
        </div>
        <div class="block" v-if="filterByParams == 'terminaltype'">
          <select
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2 mt-4"
            v-model="filterByTerminalType"
          >
            <option value="">Filter by Terminal Type</option>
            <option value="mPOS">mPOS</option>
            <option value="Android">Android</option>
            <option value="Linux">Linux</option>
          </select>
        </div>
        <div class="block" v-if="filterByParams == 'state'">
          <select
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2 mt-4"
            v-model="filterByState"
          >
            <option value="">Select state</option>
            <option :value="state" v-for="(state, i) in states" :key="i">
              {{ state }}
            </option>
          </select>
        </div>
        <div class="block" v-if="filterByParams == 'agentName'">
          <input
            type="text"
            class="inline-block px-3 ring-2 border-gray-100 mt-4 mr-2 outline-none rounded-sm w-80 text-sm py-2"
            name=""
            placeholder="Enter ISA Responsible"
            id=""
            v-model="filterByRequestingISA"
          />
        </div>
        <div class="block">
          <button
            class="inline-block bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2"
            @click="filterFunc"
          >
            Filter
          </button>
        </div>
        <div class="block">
          <button
            class="inline-block drop-shadow-2xl bg-yellow-200 text-dark py-2 px-3 mt-4 rounded-md mr-2"
            @click="clearFilterFunc"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <div class="flex bg-blue-50 p-5">
        <div class="flex-2 inline-block time-filter">
          <p>From:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startdatetime"
          />
        </div>

        <div class="flex-2 inline-block time-filter">
          <p>To:</p>
          <input
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.enddatetime"
          />
        </div>
        <div class="flex mt-5">
          <button
            @click="fetchDatedFilteredRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearDateFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Reset
          </button>
          <button
            @click="downloadDatedCSV"
            class="block uppercase shadow bg-blue-800 hover:bg-blue-700 ml-2 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-3 rounded"
          >
            Download
          </button>
        </div>
      </div>
      <div class="flex bg-gray-100 p-5 overflow-x-scroll w-full">
        <div v-for="(type, i) in types" :key="i">
          <button
            class="px-3 py-2 bg-white font-bold uppercase text-sm border-2 text-blue-800 border-grey-100 hover:border-blue-800 focus:shadow-outline"
            :class="[
              activeTab === type.status ? 'border-blue-800' : '',
              i === 0 ? 'mr-3' : 'mx-3',
            ]"
            style="width: max-content"
            @click="handleTypeSelect(type)"
          >
            {{ type.status.replaceAll("_", " ") }}
          </button>
        </div>
      </div>
      <div class="mt-5" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <thead>
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="26"
                style="background: #dee6f2"
              >
                Merchant Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Merchant Id
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Business Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Quantity
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Payment Method
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Source
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Delivery Address
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Delivery City
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Delivery State
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Delivery Phone number
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Date Requested
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Aggregator
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Terminal Type
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Accessories
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Date of preferred delivery
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Referral Code
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                LGA
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Requesting Officer
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Requesting Officer's Email
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Activation Code
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Activation Code Status
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Terminal Status
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Follow Up Date
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Comments
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Change log
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Network Provider
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(terminals, i) in filterRequestTerminals"
              :key="i"
              class="hover:bg-gray-300"
            >
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.businessId }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.businessName || "N/A" | truncate(30, "...") }}
              </td>
              <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
                {{ terminals.quantity }}
              </td>
              <td class="border px-8 py-3 max-w-sm whitespace-nowrap text-xs">
                {{
                  terminals.terminalPrice.leaseToOwn !== "0"
                    ? "Lease To Own"
                    : terminals.terminalPrice.outrightPurchase !== "0"
                    ? "Outright Purchase"
                    : "Subscription"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.source || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.deliveryAddress || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.deliveryCity || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.deliveryState || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.deliveryPhoneNumber || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(terminals.dateCreated).format(
                    "DD MMM YYYY - h:mm:ss a"
                  ) || "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.business.parent_aggregator_id || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.terminalPrice.terminalType || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <div
                  v-for="(item, index) in terminals.accessoryRequest"
                  :key="index"
                >
                  <div class="flex" v-if="item.quantity">
                    <div>Name:{{ item.accessoriesPrice.accessoriesType }},</div>
                    <br />
                    <div class="pl-1">Quantity:{{ item.quantity }}</div>
                  </div>
                </div>
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(terminals.deliveryDate, "DD-MM-YYYY h-mm-ss").format(
                    "DD MMM YYYY - h:mm:ss a"
                  ) || "N/A"
                }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.salesRefCode || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.deliverylga || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.agentName || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.supportOfficerEmail || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.activation_code || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.activationCodeStatus || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ terminals.status || "" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  terminals.followUp.follow_up !== ""
                    ? terminals.followUp.followUpDate
                    : ""
                }}
              </td>
              <td class="border px-8 py-3 text-xs">
                {{ terminals.comment || "" }}
              </td>
              <td class="border px-8 py-3 text-xs">
                {{ formatChangeLog(terminals.changeLog) || "" }}
              </td>
              <td class="border px-8 py-3 text-xs">
                {{ terminals.preferredNetworkProvider || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <button
                  @click="handleUpdateStatusModal(terminals, i)"
                  class="block uppercase shadow bg-green-800 hover:bg-green-700 focus:shadow-outline focus:outline-none text-white text-xs mb-3 py-2 px-8 rounded"
                >
                  Change Status
                </button>
                <button
                  @click="handleChangeStatusModal(terminals, i)"
                  class="block uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                >
                  Update Request
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          v-if="GET_GENERAL_TERMINALS_NEW.data.length > 99"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          Next &raquo;
        </button>
      </div>
      <div
        v-if="alertDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            class="bi bi-exclamation-triangle-fill text-green-500 mx-auto"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </svg>
          Please check your email in 5 mins for the download link.
          <br />Please note that it will take longer to generate a download link
          for larger datasets.
          <button
            @click="alertDiv = false"
            class="float-right uppercase shadow mx-auto hover:bg-red-600 bg-red-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded"
            style="width: inherit"
          >
            Close
          </button>
        </div>
      </div>
      <Modal
        size="max-w-5xl"
        v-if="changeStatusModal"
        @close="changeStatusModal = false"
        :top="`pt-10`"
      >
        <div class="flex items-center justify-between">
          <div class="rounded-lg bg-white p-5 grid gap-4 w-full text-center">
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Merchant Id</div>
                <div class="flex text-gray-900">
                  {{ businessId || "N/A" }}
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Business Name:</div>
                <div class="flex text-gray-900">
                  {{ businessName }}
                </div>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Quantity</div>
                <div class="flex text-gray-900">
                  <input
                    type="number"
                    class="p-2 ring-1 w-64 outline-none ring-gray-300 rounded-sm text-sm"
                    v-model="quantity"
                    min="1"
                    v-on:keypress="NumbersOnly"
                  />
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Type:</div>
                <div class="flex text-gray-900 font-bold">
                  <select
                    class="inline-block p-2 ring-1 outline-none ring-gray-300 rounded-sm w-64 text-sm"
                    name=""
                    id=""
                    v-model="selectedTerminalType"
                    required
                  >
                    <option value="" disabled>----</option>
                    <option
                      :value="terminalType.terminalType"
                      v-for="(terminalType, i) in terminalTypeList"
                      :key="i"
                    >
                      {{ terminalType.terminalType }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Payment Method:</div>
                <div class="flex text-gray-900 font-bold">
                  <select
                    class="inline-block p-2 ring-1 outline-none ring-gray-300 rounded-sm w-64 text-sm"
                    name=""
                    id=""
                    v-model="selectedPaymentMethod"
                    required
                  >
                    <option value="" disabled>----</option>
                    <option
                      :value="payment.name"
                      v-for="(payment, i) in paymentMethodList"
                      :key="i"
                    >
                      {{ payment.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Date Requested</div>
                <div class="flex text-gray-900 font-bold">
                  <input
                    type="date"
                    class="p-2 ring-1 w-64 outline-none ring-gray-300 rounded-sm text-sm"
                    v-model="dateRequested"
                    disabled
                  />
                </div>
              </div>

              <div class="w-full xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Delivery Address</div>
                <div class="flex text-gray-900 font-bold">
                  <input
                    type="text"
                    class="p-2 ring-1 w-64 outline-none ring-gray-300 rounded-sm text-sm"
                    v-model="deliveryAddress"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Delivery City</div>
                <div class="flex text-gray-900 font-bold">
                  <input
                    type="text"
                    class="p-2 ring-1 w-64 outline-none ring-gray-300 rounded-sm text-sm"
                    v-model="deliveryCity"
                  />
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Delivery State</div>
                <div class="flex text-gray-900 font-bold">
                  <select
                    type="text"
                    class="inline-block p-3 ring-1 w-64 outline-none ring-gray-300 rounded-sm w-64 text-sm"
                    name=""
                    placeholder="Enter Delivery State"
                    id=""
                    v-model="deliveryState"
                    required
                  >
                    <option value="" disabled>Select state</option>
                    <option
                      :value="state"
                      v-for="(state, i) in states"
                      :key="i"
                    >
                      {{ state }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Delivery Phonenumber</div>
                <div class="flex text-gray-900 font-bold">
                  <input
                    type="text"
                    class="p-2 ring-1 w-64 outline-none ring-gray-300 rounded-sm text-sm"
                    v-model="deliveryPhoneNumber"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Preferred Network Provider</div>
                <div class="flex text-gray-900 font-bold">
                  <select
                    v-model="selectedPreferredNetworkProvider"
                    class="border-2 w-64 px-2 py-2"
                  >
                    <option :value="null" disabled>Select Provider</option>
                    <option
                      v-for="provider in preferredNetworkProvider"
                      :key="provider"
                      :value="provider"
                    >
                      {{ provider }}
                    </option>
                  </select>
                </div>
              </div>

              <div
                v-if="selectedTerminalType === 'MPOS'"
                class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3"
              >
                <div class="flex font-bold">Accessories</div>
                <div class="flex text-gray-900 font-bold">
                  <div>
                    <select
                      class="border-2 w-64 px-2 py-2"
                      v-model="accessoriesName"
                    >
                      <option value="">Select an accessory</option>
                      <option value="Bluetooth Printer">
                        Bluetooth Printer
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  selectedTerminalType.toUpperCase() === 'MPOS' &&
                    accessoriesName !== ''
                "
                class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3"
              >
                <div class="flex font-bold">Quantity</div>
                <div class="flex text-gray-900 font-bold">
                  <input
                    type="number"
                    class="p-2 ring-1 w-64 outline-none ring-gray-300 rounded-sm text-sm"
                    v-model="accessoriesQuantity"
                    min="1"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full xl:my-4 xl:px-4 xl:w-1/3">
                <button
                  @click="handleChangeTermimalRequestDetails"
                  class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 rounded"
                >
                  Update Request
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        size="max-w-5xl"
        v-if="updateStatusModal"
        @close="updateStatusModal = false"
        :top="`pt-10`"
      >
        <div class="flex items-center justify-between">
          <div class="rounded-lg bg-white p-5 grid gap-4 w-full text-center">
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Merchant Id</div>
                <div class="flex text-gray-900">
                  {{ businessId || "N/A" }}
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Business Name:</div>
                <div class="flex text-gray-900">
                  {{ businessName }}
                </div>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Quantity</div>
                <div class="flex text-gray-900">
                  {{ quantity }}
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Type:</div>
                <div class="flex text-gray-900 font-bold">
                  <div class="flex text-gray-900">
                    {{ selectedTerminalType }}
                  </div>
                </div>
              </div>

              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Change Status:</div>
                <div class="flex text-gray-900 font-bold">
                  <select
                    v-model="newStatus"
                    class="border-2 px-2 py-2 w-64"
                    name=""
                    id=""
                  >
                    <option :value="null" disabled>Select Status</option>
                    <option
                      v-for="type in types"
                      :key="type.number"
                      :value="type.status"
                      v-show="
                        type.status !== 'FOLLOW_UP' && type.status !== 'ALL'
                      "
                    >
                      {{ type.status }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div
                v-if="
                  newStatus === 'DISPATCHED' ||
                    (newStatus === 'NOT_APPROVED' && oldState === 'DISPATCHED')
                "
                class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3"
              >
                <div class="flex font-bold">Support Officer's Email:</div>
                <div class="flex text-gray-900 font-bold">
                  <div>
                    <input
                      type="text"
                      class="p-2 ring-1 w-64 outline-none ring-gray-300 rounded-sm text-sm"
                      v-model="supportOfficerEmail"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="newStatus === 'DISPATCHED'"
                class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3"
              >
                <div class="flex font-bold">Expected delivery date</div>
                <div class="flex text-gray-900 font-bold">
                  <input
                    type="date"
                    class="p-2 ring-1 w-64 outline-none ring-gray-300 rounded-sm text-sm"
                    v-model="expectedDeliveryDate"
                  />
                </div>
              </div>
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-1/3">
                <div class="flex font-bold">Follow Up</div>
                <div class="flex text-gray-900 font-bold">
                  <select
                    v-model="followUpSwitch"
                    class="border-2 w-64 p-2"
                    name=""
                    id=""
                  >
                    <option value="null" disabled>Select option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>

              <div
                v-if="followUpSwitch === 'yes'"
                class="w-full xl:my-4 xl:px-4 xl:w-1/3"
              >
                <div class="flex font-bold">Follow Up Date</div>
                <div class="flex text-gray-900 font-bold">
                  <div>
                    <input
                      type="date"
                      class="p-2 ring-1 w-64 outline-none ring-gray-300 rounded-sm text-sm"
                      v-model="followupDate"
                      :min="dateData.startdatetime"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex flex-wrap overflow-hidden xl:-mx-4"
              v-if="followUpSwitch === 'yes' || newStatus === 'NOT_APPROVED'"
            >
              <div class="w-full overflow-hidden xl:my-4 xl:px-4 xl:w-full">
                <div class="flex font-bold">
                  Comment <span class="text-red-600"> *</span>
                </div>
                <div class="flex text-gray-900 font-bold">
                  <textarea
                    class="border-2 border-gray-400 rounded-none w-full p-2"
                    placeholder="Please enter a reason"
                    id=""
                    v-model="comment"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="flex flex-wrap overflow-hidden xl:-mx-4">
              <div class="w-full xl:my-4 xl:px-4 xl:w-1/3">
                <button
                  @click="handleUpdateTermimalRequest"
                  class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 rounded"
                >
                  Update Status
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "../components/Modal.vue";
import moment from "moment";
import Loading from "./general-components/Loading.vue";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      updateStatusModal: false,
      accounts: null,
      current: "NEW",
      dateData: null,
      currentPage: 1,
      totalPages: 0,
      alertDiv: false,
      isDisabled: true,
      dateFilter: false,
      filterActive: false,
      searchActive: false,
      followUpSwitch: null,
      changeStatusModal: false,
      c: false,
      requestId: "",
      filterByParams: "",
      filterByStatus: "",
      filterByState: "",
      filterByRequestingISA: "",
      filterByTerminalType: "",
      filterToggle: false,
      terminalRequests: [],
      businessId: "",
      businessName: "",
      quantity: "",
      selectedTerminalType: "",
      selectedPaymentMethod: "",
      deliveryDate: "",
      deliveryAddress: "",
      deliveryCity: "",
      deliveryState: "",
      deliveryPhoneNumber: "",
      oldState: "",
      newStatus: "",
      comment: "",
      accessoriesName: "",
      accessoriesQuantity: "",
      supportOfficerEmail: "",
      expectedDeliveryDate: "",
      dateRequested: "",
      states: [
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "FCT - Abuja",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara",
      ],
      followupDate: null,
      selectedPreferredNetworkProvider: null,
      preferredNetworkProvider: ["MTN", "GLO", "9mobile", "Airtel"],
      types: [
        {
          status: "ALL",
        },
        {
          status: "NEW",
        },
        {
          status: "APPROVED_TO_BE_DEPLOYED",
        },
        {
          status: "DISPATCHED",
        },
        {
          status: "DEPLOYED",
        },
        {
          status: "NOT_APPROVED",
        },
        {
          status: "FOLLOW_UP",
        },
        {
          status: "CANCELLED",
        },
      ],
      terminalTypeList: [],
      paymentMethodList: [],
      activeTab: "ALL",
      activeUrl: "",
    };
  },
  components: {
    Modal,
    Loading,
    // Multiselect,
  },
  computed: {
    ...mapGetters(["GET_GENERAL_TERMINALS_NEW"]),
    filterRequestTerminals() {
      //   return this.terminalRequests;
      // let k = this.GET_GENERAL_TERMINALS_NEW.data.filter(
      //   (account) =>
      //     (account.businessId &&
      //       account.businessId
      //         .toLowerCase()
      //         .includes(this.search.toLowerCase())) ||
      //     (account.businessName &&
      //       account.businessName
      //         .toLowerCase()
      //         .includes(this.search.toLowerCase())) ||
      //     (account.business.email &&
      //       account.business.email
      //         .toLowerCase()
      //         .includes(this.search.toLowerCase()))
      // );
      return this.GET_GENERAL_TERMINALS_NEW.data;
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startdatetime: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.fetchTerminalRequests();
    this.getterminatypes();
  },
  methods: {
    ...mapActions([
      "FETCH_GENERAL_TERMINAL_REQUESTS_NEW",
      "FETCH_GENERAL_TERMINAL_REQUESTS_FOLLOW_UP",
      "UPDATE_TERMINAL_REQUEST",
      "NEW_UPDATE_TERMINAL_REQUEST",
      "DOWNLOAD_GENERAL_TERMINAL_REQUEST_NEW_CSV",
      "FETCH_GENERAL_FILTERED_TERMINAL_REQUESTS",
      "DOWNLOAD_GENERAL_DATE_TERMINAL_REQUESTS_LOG_CSV",
    ]),
    async fetchTerminalRequests() {
      try {
        this.isLoading = true;
        let response = await this.$store.dispatch(
          "FETCH_GENERAL_TERMINAL_REQUESTS_NEW"
        );
        this.isLoading = false;
        if (response.status) {
          this.terminalRequests = response.data;
        } else {
          alert("Sorry, network error occured.");
        }
      } catch (error) {}
      this.isLoading = false;
    },
    async fetchTerminalRequestsFollowUp() {
      try {
        this.isLoading = true;
        let response = await this.$store.dispatch(
          "FETCH_GENERAL_TERMINAL_REQUESTS_FOLLOW_UP"
        );
        this.isLoading = false;
        if (!response.status) {
          alert("Sorry, network error occured.");
        }
      } catch (error) {}
      this.isLoading = false;
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatChangeLog(changeLog) {
      let log = `Date:` + changeLog.date_changed;
      log += ` | Agent Name: ` + changeLog.agent_name || "N/A";
      log += "| From Status: " + changeLog.from_status || "N/A";
      log += "| To Status: " + changeLog.to_status || "N/A";
      return log;
    },
    async handleUpdateTermimalRequest() {
      this.businessId;
      this.businessName;
      const status = this.newStatus;
      const comment = this.comment;
      const requestId = this.requestId;
      const supportOfficerEmail = this.supportOfficerEmail;

      const followUp = {
        follow_up: "follow_up",
        followUpDate: this.followupDate || "",
      };
      let followUpDate = this.followUpSwitch === "yes" ? followUp : "";

      let payload = {
        comment,
        status,
        requestId,
        followUp: followUpDate,
        supportOfficerEmail,
        expectedDeliveryDate: this.expectedDeliveryDate,
      };

      if (this.followUpSwitch === "yes" && comment === "") {
        alert("Comment is required.");
        return;
      }

      if (status === "NOT_APPROVED" && comment === "") {
        alert("Comment is required.");
        return;
      }

      if (status === "DISPATCHED" && this.expectedDeliveryDate === "") {
        alert("Delivery date is required.");
        return;
      }

      if (status === "DISPATCHED" && this.supportOfficerEmail === "") {
        alert("Support officer's email is required.");
        return;
      }

      if (this.followUpSwitch === "yes" && this.followupDate === "") {
        alert("Follow up date is required.");
        return;
      }

      try {
        this.isLoading = true;
        let res = await this.$store.dispatch(
          "UPDATE_TERMINAL_REQUEST",
          payload
        );

        this.updateStatusModal = false;
        // this.fetchTerminalRequests();

        let message = "";

        if (res.status) {
          message += res.message;
        } else {
          const new_res = JSON.parse(JSON.stringify(res));
          const updatedMessage =
            new_res.message === "Request failed with status code 400"
              ? "An error occured; activation code already exist for this Terminal request"
              : new_res.message;
          message += updatedMessage;
        }

        alert(message);
        if (!this.filterActive) {
          this.fetchTerminalRequests();
        }
        this.isLoading = false;
      } catch (error) {}
    },
    async handleChangeTermimalRequestDetails() {
      this.businessId;
      this.businessName;
      const quantity = parseInt(this.quantity, 10);
      const terminalType = this.selectedTerminalType;
      const paymentMethod = this.selectedPaymentMethod; //selectedPaymentMethod
      const deliveryDate = this.deliveryDate;
      const deliveryAddress = this.deliveryAddress;
      const deliveryCity = this.deliveryCity;
      const deliveryState = this.deliveryState;
      const deliveryPhoneNumber = this.deliveryPhoneNumber;
      const status = this.newStatus;
      const comment = this.comment;
      const requestId = this.requestId;
      const supportOfficerEmail = this.supportOfficerEmail;
      const preferredNetworkProvider = this.selectedPreferredNetworkProvider;
      let accessoryRequest = "";
      if (this.accessoriesName !== "") {
        accessoryRequest = [
          {
            type: this.accessoriesName,
            quantity: parseInt(this.accessoriesQuantity, 10),
          },
        ];
      } else {
        accessoryRequest = [];
      }

      let payload = {
        terminalType,
        paymentMethod,
        deliveryAddress,
        deliveryState,
        quantity,
        deliveryCity,
        deliveryPhoneNumber,
        comment,
        status,
        requestId,
        deliveryDate,
        accessoryRequest,
        request_updated: true,
        preferredNetworkProvider,
      };

      try {
        this.isLoading = true;
        let res = await this.$store.dispatch(
          "NEW_UPDATE_TERMINAL_REQUEST",
          payload
        );

        this.changeStatusModal = false;
        alert(res.message);
        if (!this.filterActive) {
          this.fetchTerminalRequests();
        }
        this.isLoading = false;

        // this.$router.go();
      } catch (error) {
        console.log(error);
      }
    },
    async downloadCSV() {
      try {
        this.isLoading = true;
        let res = await this.$store.dispatch(
          "DOWNLOAD_GENERAL_TERMINAL_REQUEST_NEW_CSV"
        );
        this.isLoading = false;
        if (!res.status) {
          alert(res.message);
        } else {
          window.open(res.data, "_blank");
        }
      } catch (error) {}
    },
    async handleTypeSelect(type) {
      this.isLoading = true;

      this.activeTab = type.status;
      let filterParams = type.status.replaceAll(" ", "_");
      this.filterByStatus = filterParams;

      this.currentPage = 1;
      if (type.status === "ALL") {
        let filterBy = "";
        this.filterActive = false;
        this.fetchTerminalRequests();
      } else if (type.status === "FOLLOW_UP") {
        let filterBy = "followUp";
        this.filterByParams = filterBy;
        this.filterActive = true;
        this.fetchTerminalRequestsFollowUp();
      } else {
        let filterBy = "status";
        this.filterByParams = filterBy;
        this.filterActive = true;
        let payload = { filterBy, filterParams };
        try {
          let res = await this.$store.dispatch(
            "FETCH_GENERAL_FILTERED_TERMINAL_REQUESTS",
            payload
          );
          this.isLoading = false;
          if (res.data.length < 1) {
            alert("Could not find any data");
            // this.$router.go();
          }
        } catch (error) {}
        this.isLoading = false;
      }
    },
    handleUpdateStatusModal(terminal, index) {
      this.selectedPreferredNetworkProvider = terminal.preferredNetworkProvider;
      this.updateStatusModal = true;
      this.requestId = terminal.requestId;
      this.businessId = terminal.businessId;
      this.businessName = terminal.businessName;
      this.quantity = terminal.quantity;
      this.selectedTerminalType = terminal.terminalPrice.terminalType;
      this.selectedPaymentMethod =
        terminal.terminalPrice.leaseToOwn !== "0"
          ? "leaseToOwn"
          : terminal.terminalPrice.outrightPurchase !== "0"
          ? "outrightPurchase"
          : "Subscription";

      if (this.selectedTerminalType) {
        const selectedTerminalList = this.terminalTypeList.filter(
          (el) => el.terminalType === this.selectedTerminalType
        );
        this.paymentMethodList = selectedTerminalList[0].pricing;
      }
      this.deliveryDate = moment(terminal.deliveryDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      this.dateRequested = moment(terminal.dateCreated, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      );

      this.deliveryAddress = terminal.deliveryAddress;
      this.deliveryCity = terminal.deliveryCity;
      this.deliveryState = terminal.deliveryState;
      this.deliveryPhoneNumber = terminal.deliveryPhoneNumber;
      this.oldState = terminal.changeLog.to_status;
      this.newStatus = terminal.status;
      this.comment = terminal.comment;
      this.expectedDeliveryDate = moment(
        terminal.expectedDeliveryDate,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      console.log(
        moment(terminal.expectedDeliveryDate, "DD/MM/YYYY").format("YYYY-MM-DD")
      );

      this.supportOfficerEmail = terminal.supportOfficerEmail;

      this.followUpSwitch =
        terminal.followUp.follow_up === "FOLLOW_UP" ? "yes" : "no";
      this.followupDate = terminal.followUp.followUpDate;

      if (terminal.accessoryRequest.length > 0) {
        this.accessoriesName =
          terminal.accessoryRequest !== undefined &&
          terminal.accessoryRequest[0].accessoriesPrice.accessoriesType;
        this.accessoriesQuantity =
          terminal.accessoryRequest !== undefined &&
          terminal.accessoryRequest[0].quantity;
      }
    },
    handleChangeStatusModal(terminal, index) {
      // console.log(terminal);
      this.selectedPreferredNetworkProvider = terminal.preferredNetworkProvider;
      this.changeStatusModal = true;
      this.requestId = terminal.requestId;
      this.businessId = terminal.businessId;
      this.businessName = terminal.businessName;
      this.quantity = terminal.quantity;
      this.selectedTerminalType = terminal.terminalPrice.terminalType;
      this.selectedPaymentMethod =
        terminal.terminalPrice.leaseToOwn !== "0"
          ? "leaseToOwn"
          : terminal.terminalPrice.outrightPurchase !== "0"
          ? "outrightPurchase"
          : "Subscription";

      if (this.selectedTerminalType) {
        const selectedTerminalList = this.terminalTypeList.filter(
          (el) => el.terminalType === this.selectedTerminalType
        );
        this.paymentMethodList = selectedTerminalList[0].pricing;
      }
      this.deliveryDate = terminal.deliveryDate;

      this.dateRequested = moment(terminal.dateCreated).format("YYYY-MM-DD");

      this.deliveryAddress = terminal.deliveryAddress;
      this.deliveryCity = terminal.deliveryCity;
      this.deliveryState = terminal.deliveryState;
      this.deliveryPhoneNumber = terminal.deliveryPhoneNumber;
      this.oldState = terminal.changeLog.to_status;
      this.newStatus = terminal.status;
      this.comment = terminal.comment;

      this.expectedDeliveryDate = terminal.expectedDeliveryDate;
      this.supportOfficerEmail = terminal.supportOfficerEmail;

      if (terminal.accessoryRequest.length > 0) {
        this.accessoriesName =
          terminal.accessoryRequest !== undefined &&
          terminal.accessoryRequest[0].accessoriesPrice.accessoriesType;
        this.accessoriesQuantity =
          terminal.accessoryRequest !== undefined &&
          terminal.accessoryRequest[0].quantity;
      }
    },
    async filterFunc() {
      this.isLoading = true;
      const filterBy = this.filterByParams;
      let filterParams = "";
      this.filterActive = true;
      this.currentPage = 1;
      if (filterBy === "terminaltype") {
        filterParams = this.filterByTerminalType;
      } else if (filterBy === "state") {
        filterParams = this.filterByState;
      } else if (filterBy === "agentName") {
        filterParams = this.filterByRequestingISA;
      } else if (filterBy === "status") {
        filterParams = this.filterByStatus;
      }
      let payload = { filterBy, filterParams };
      try {
        let res = await this.$store.dispatch(
          "FETCH_GENERAL_FILTERED_TERMINAL_REQUESTS",
          payload
        );
        this.isLoading = false;
        if (res.data.length < 1) {
          alert("Could not find any data");
          // this.$router.go();
        }
      } catch (error) {}
      this.isLoading = false;
    },
    clearFilterFunc() {
      this.filterByParams = "";
      this.currentPage = 1;
      this.fetchTerminalRequests();
      this.filterActive = false;
    },
    async downloadDatedCSV() {
      if (
        this.dateData.startdatetime > this.dateData.enddatetime ||
        this.dateData.startdatetime === this.dateData.enddatetime
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.isLoading = true;
        try {
          let res = await this.$store.dispatch(
            "DOWNLOAD_GENERAL_DATE_TERMINAL_REQUESTS_LOG_CSV",
            { date: this.dateData }
          );

          if (!res.status) {
            alert("Could not find any data within the date range");
            this.$router.go();
          } else {
            // console.log(res.data);
            this.isLoading = false;
            // this.alertDiv = true;
            window.open(res.data, "_blank");
          }
        } catch (error) {}
        this.isLoading = false;
      }
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      const filterBy = this.filterByParams;
      let filterParams = "";
      if (filterBy === "terminaltype") {
        filterParams = this.filterByTerminalType;
      } else if (filterBy === "state") {
        filterParams = this.filterByState;
      } else if (filterBy === "agentName") {
        filterParams = this.filterByRequestingISA;
      } else if (filterBy === "status") {
        filterParams = this.filterByStatus;
      }
      try {
        let res = await this.$store.dispatch(
          "FETCH_GENERAL_PAGINATE_TERMINAL_REQUESTS_NEW",
          {
            page: currPage - 1,
            date: this.dateData,
            dateFilter: this.dateFilter,
            filterActive: this.filterActive,
            searchActive: this.searchActive,
            search: this.search,
            filterBy,
            filterParams,
          }
        );
        if (res.status) {
          this.isLoading = false;
          this.currentPage -= 1;
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      const filterBy = this.filterByParams;
      let filterParams = "";
      if (filterBy === "terminaltype") {
        filterParams = this.filterByTerminalType;
      } else if (filterBy === "state") {
        filterParams = this.filterByState;
      } else if (filterBy === "agentName") {
        filterParams = this.filterByRequestingISA;
      } else if (filterBy === "status") {
        filterParams = this.filterByStatus;
      }

      try {
        let res = await this.$store.dispatch(
          "FETCH_GENERAL_PAGINATE_TERMINAL_REQUESTS_NEW",
          {
            page: currPage + 1,
            date: this.dateData,
            dateFiltered: this.dateFilter,
            filterActive: this.filterActive,
            searchActive: this.searchActive,
            search: this.search,
            filterBy,
            filterParams,
          }
        );
        if (res.status) {
          this.isLoading = false;
          this.currentPage += 1;
        }
        this.isLoading = false;
      } catch (error) {}
      this.isLoading = false;
    },
    async handleSearch() {
      this.isLoading = true;
      if (this.search !== "") {
        this.currentPage = 1;

        try {
          let res = await this.$store.dispatch(
            "SEARCH_GENERAL_TERMINAL_REQUESTS_NEW",
            this.search
          );
          this.searchActive = true;

          if (!res.status) {
            this.isLoading = false;
            alert("Could not find " + this.search + " & " + res.message);
            // this.isLoading = false;
            // this.$router.go();
          } else {
            this.isLoading = false;
            this.terminalRequests = res.data;
          }
          this.isLoading = false;
        } catch (error) {}
      }
    },
    async fetchDatedFilteredRecords() {
      if (
        this.dateData.startdatetime > this.dateData.enddatetime ||
        this.dateData.startdatetime === this.dateData.enddatetime
      ) {
        alert(
          "Sorry, you entered an invalid date pair. Please check it and try again."
        );
      } else {
        this.isLoading = true;
        this.dateFilter = true;
        this.currentPage = 1;

        try {
          let res = await this.$store.dispatch(
            "FETCH_GENERAL_DATED_TERMINAL_REQUESTS",
            { date: this.dateData }
          );

          if (res.data.item === null) {
            alert("Could not find any data within the date range");
            this.$router.go();
          } else {
            this.terminalRequests = response.data;
          }
        } catch (error) {}
      }

      this.isLoading = false;
    },

    async getterminatypes() {
      let res = await this.$store.dispatch("FETCH_ALL_TERMINAL_TYPES");
      this.terminalTypeList = res.data;
    },

    clearFilter() {
      this.fetchTerminalRequests();
      this.currentPage = 1;
      this.search = "";
      this.searchActive = false;
    },
    clearDateFilter() {
      this.fetchTerminalRequests();
      this.currentPage = 1;
      this.dateFilter = false;
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
